export function loadGoogleTranslate() {
  return new Promise((resolve, reject) => {
    if (document.getElementById('google-translate-script')) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = 'google-translate-script';
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.type = 'text/javascript';
    script.async = true;

    script.onload = resolve;
    script.onerror = reject;

    document.head.appendChild(script);
  });
}

window.googleTranslateElementInit = function () {
  new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
};