import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Router from "vue-router";
// import store from '../store/index'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",

    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"), // this style
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",

    component: () => import(/* webpackChunkName: "fortgotpasswor" */ "../views/forgotPassword.vue"), // this style
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import(/* webpackChunkName: "fortgotpasswor" */ "../views/resetPassword.vue"), // this style
  },
  {
    path: "/viewer",
    // path: '/viewer/:uid',
    name: "Viewer",

    component: () =>
      import(/* webpackChunkName: "Viewer" */ "../views/Viewer.vue"), // this style
  },
  {
    path: "/adminmaster",
    name: "AdminMaster",

    component: () =>
      import(/* webpackChunkName: "AdminMaster" */ "../views/AdminMaster.vue"), // this style
  },
  {
    path: "/operator",
    name: "Operator",

    component: () => import("../views/Operator/Operator.vue"), // this style
  },
  {
    path: "/radmaster",
    name: "RadMaster",

    component: () =>
      import(/* webpackChunkName: "RadMaster" */ "../views/RadMaster.vue"), // this style
  },
  {
    path: "/refmaster",
    name: "refMaster",

    component: () =>
      import(/* webpackChunkName: "RadMaster" */ "../views/referrerMaster.vue"), // this style
  },
  {
    path: "/referrer",
    name: "Referrer",
    
    component: () =>
      import( "../views/Referrer.vue"), // this style
  },
  {
    path: "/updateProfile",
    name: "UpdateProfile",
    
    component: () =>
      import( "../views/UpdateProfile.vue"), // this style
  },
  {
    path: "/physician",
    name: "PhysMaster",

    component: () =>
      import(/* webpackChunkName: "PhysMaster" */ "../views/physMaster.vue"), // this style
  },
  {
    path: "/diagmaster",
    name: "DiagMaster",

    component: () =>
      import(/* webpackChunkName: "DiagMaster" */ "../views/DiagMaster.vue"), // this style
  },
  {
    path: "/diagcenter",
    name: "DiagCenter",

    component: () =>
      import(/* webpackChunkName: "DiagCenter" */ "../views/DiagCenter.vue"), // this style
  },
  {
    path: "/radiologist",
    name: "Radiologist",

    component: () =>
      import(/* webpackChunkName: "Radiologist" */ "../views/Radiologist.vue"), // this style
  },
  {
    path: "/adminsetting",
    name: "Setting",

    component: () =>
      import(/* webpackChunkName: "Settings" */ "../views/AdminSetting.vue"), // this style
  },
  {
    path: "/radsetting",
    name: "RadSetting",

    component: () =>
      import(
        /* webpackChunkName: "Physician Settings" */ "../views/RadSetting.vue"
      ), // this style
  },
  {
    path: "/physetting",
    name: "PhysSetting",

    component: () =>
      import(/* webpackChunkName: "Rad Settings" */ "../views/physSetting.vue"), // this style
  },
  {
    path: "/diagsetting",
    name: "DiagSetting",

    component: () =>
      import(
        /* webpackChunkName: "Diag Settings" */ "../views/DiagSetting.vue"
      ), // this style
  },
  {
    path: "/theme",
    name: "Theme",

    component: () =>
      import(/* webpackChunkName: "General Settings" */ "../views/theme.vue"), // this style
  },
  {
    path: "/addrad",
    name: "AddRad",

    component: () =>
      import(/* webpackChunkName: "Add Radiologist" */ "../views/addRad.vue"), // this style
  },
  {
    path: "/addphys",
    name: "AddPhys",

    component: () =>
      import(/* webpackChunkName: "Add Physician" */ "../views/addPhys.vue"), // this style
  },
  {
    path: "/addpatient",
    name: "AddPatient",

    component: () =>
      import(/* webpackChunkName: "Add Patient" */ "../views/addPatient.vue"), // this style
  },
  {
    path: "/adddiag",
    name: "AddDiag",

    component: () =>
      import(/* webpackChunkName: "Add Diag" */ "../views/addDiag.vue"), // this style
  },
  {
    path: "/adddiagadmin",
    name: "AddDiagAdmin",

    component: () =>
      import(
        /* webpackChunkName: "Add Diag Admin" */ "../views/addDiagAdmin.vue"
      ), // this style
  },
  {
    path: "/addtechnician",
    name: "addTechnician",

    component: () =>
      import(
        /* webpackChunkName: "Add Technician" */ "../views/addTechnician.vue"
      ), // this style
  },
  {
    path: "/editrad",
    name: "DelRad",

    component: () =>
      import(
        /* webpackChunkName: "Delete Radiologist" */ "../views/editRad.vue"
      ), // this style
  },
  {
    path: "/changepassadmin",
    name: "changePassAdmin",

    component: () =>
      import(
        /* webpackChunkName: "change PassAdmin" */ "../views/changePassAdmin.vue"
      ), // this style
  },
  {
    path: "/technician",
    name: "TechnicianList",

    component: () =>
      import(
        /* webpackChunkName: "Technician Table" */ "../views/TechnicianList.vue"
      ), // this style
  },
  {
    path: "/manager",
    name: "ManagerList",

    component: () =>
      import(
        /* webpackChunkName: "ManagerList Table" */ "../views/ManagerList.vue"
      ), // this style
  },
  {
    path: "/rad",
    name: "RadList",

    component: () =>
      import(
        /* webpackChunkName: "Radiologist Table" */ "../views/RadList.vue"
      ), // this style
  },
  {
    path: "/mapping",
    name: "RadDiagMapping",

    component: () =>
      import(
        /* webpackChunkName: "Radiologist-Diagcentre mapping" */ "../views/RadDiagMapping.vue"
      ), // this style
  },
  {
    path: "/error",
    name: "404_Error",

    component: () =>
      import(/* webpackChunkName: "error" */ "../views/404_Error.vue"), // this style
  },
  {
    path: "/userhome",
    name: "User_Home",

    component: () =>
      import(
        /* webpackChunkName: "Patient Login page" */ "../views/Patient/UserHome.vue"
      ), // this style
  },
  {
    path: "/user",
    name: "User_login",

    component: () =>
      import(
        /* webpackChunkName: "Patient Login page" */ "../views/Patient/User_login.vue"
      ), // this style
  },

  {
    path: "/data",
    name: "Summary",
    // Download final summary
    component: () => import("../views/ReportSummary/ExamData.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
