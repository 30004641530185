
<template>
    <div id="google_translate_element"></div>
  </template>
  
  <script>
  import { loadGoogleTranslate } from './googleTranslate';
  
  export default {
    name: 'GoogleTranslate',
    mounted() {
      loadGoogleTranslate().catch((err) => {
        console.error('Failed to load Google Translate script:', err);
      });
    },
  };
  </script>
  
  <style scoped>

div#google_translate_element {
    position: fixed;
    background: #93b3d6; /* Change background color to a more button-like color */
    padding: 15px 30px;
    border-radius: 10px;
    margin: 20px;
    bottom: 20px;
    right: 30px;
    z-index: 1000; /* Ensure it appears above other elements */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.5);
    color: #fff; /* Button text color */
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    border: none; /* Remove default border */
    display: flex;
    align-items: center;
    justify-content: center;
}

div#google_translate_element:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), inset 0 1px 0 rgba(255, 255, 255, 0.6);
    transform: translateY(-3px);
}

div#google_translate_element:active {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.6);
    transform: translateY(0);
}


  </style>
  